
$color: #54FE55;
$color2: #1a4f1a;
$glowSize: 10px;

.error404-container{
	margin: 0;
	font-family: 'Press Start 2P', cursive;
	box-sizing: border-box;
	background: black;
}

.error403{
   padding: 1rem;
   display: flex;
   height: 100%;
   justify-content: center; 
   align-items: center;
   color: $color;
   text-shadow: 0px 0px $glowSize ;
   font-size: 6rem;
   flex-direction: column;
   .txt {
      font-size: 1.8rem;
   }
}
@keyframes blink {
    0%   {opacity: 0}
    49%  {opacity: 0}
    50%  {opacity: 1}
    100% {opacity: 1}
}

.blink {
   animation-name: blink;
    animation-duration: 1s;
   animation-iteration-count: infinite;
}